$mobile: 600px;

@mixin above($breakpoint) {
  @media screen and (min-width: $breakpoint + 1) {
    @content
  }
}

@mixin below($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content
  }
}

$content-width: 700px;
html, body {
	padding: 0;
	margin: 0;
}

body {
	color: black;
	background: gray;
	font-family: "helvetica neue", helvetica, sans-serif;
	letter-spacing: 0.05em;
	font-size: 13px;

	@include above($mobile) {
		font-size: 15px;
	}
}

.mobile-only {
	@include above($mobile) {
		display: none;
	}
}

.above-mobile-only {
	@include below($mobile) {
		display: none;
	}
}

h1, h2, h3, h4 {
	font-size: inherit;
	font-weight: inherit;
}

a {
	color: inherit;
	cursor: pointer;
	text-decoration: none;
	// text-decoration: underline;
	// text-underline-position: under;

	&:hover {
		text-decoration: none
	};

	// &.no-underline {
	// 	text-decoration: none;
	// 	&:hover {
	// 		text-decoration: underline;
	// 		text-underline-position: under;
	// 	}
	// }
}
video {
		height: 100vh;
		height: calc(var(--vh) * 100);
    width: 100vw;
    max-width: 100vw;
    object-fit: cover;
    position: fixed;
    left: 0 ;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -10;
}


.about-text {
	text-align: center;
	width: 750px;
	max-width: 90%;
	margin: 0 auto;
	line-height: 1.4em;
	font-size: 20px;
	text-transform: uppercase;
	color: white;

	p + p {
		margin-top: 1.4em;
	}
	h1 {
		margin-bottom: 2.6em
	}

	.email-p {
		margin-top: 2.6em
	}
}

header {
	top: 0;
}

footer {
	bottom: 0;
}

main {
	height: 100vh;
	height: calc(var(--vh) * 100);
	display: flex;
	align-items: center;
	z-index: 0;
}